$dark-scarlet: #3e2723;
$dark-red: #4e312c;
$dark-scarlet-light: #5f403b;
$light-brilliant-gamboge: #fbb03b;
$white: #ffffff;

$brown: $dark-scarlet;
$brown-light: #623f39;
$orange: $light-brilliant-gamboge;
// $body-bg: linear-gradient(180deg, #fbfbfb 0%, #f8f8f8 48.08%, #f5f5f5 100%);

$enable-rounded: false;
$card-border-width: 0;

$blue: #5f85db;

$pagination-color: $brown;
$pagination-bg: $white;
$pagination-border-width: 0;

$pagination-active-color: $white;
$pagination-active-bg: $brown;

$navbar-brand-height: 3.466rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px,
);

$paragraph-margin-bottom: 10px;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

/****
** COMPONENT : PANE RIGHT METRICS
 */
$pane-metrics: 80px;
$pane-metrics-open: 230px;

/****
** COMPONENT: PANE LEFT RESULTS
 */
$pane-results: 80px;
