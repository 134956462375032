.another-popup .leaflet-popup-close-button {
  display: none;
}

.leaflet-popup-content {
  margin: 0;
  .leaflet-popup-title {
    font-size: 18px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 8px;
  }

  .leaflet-popup-address {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

/*another-popop style*/
.another-popup .leaflet-popup-content-wrapper {
  background: white;
  color: black;
  font-size: 12px;
  line-height: 24px;
  border-radius: 35px;
  width: 300px;
  min-width: 270px;
  z-index: 2;
  padding: 0px;
}

.another-popup .leaflet-popup-content-wrapper a {
  color: rgba(200, 200, 200, 0.1);
}
.another-popup .leaflet-popup-tip {
  background: transparent;
  border: none;
  box-shadow: none;
}
