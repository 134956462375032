.card-weather {
  .weather-description {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 24px;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .weather-city,
  .weather-date {
    font-size: 24px;
    margin-bottom: 0;
    line-height: 1;
  }

  .weather-date {
    margin-bottom: 30px;
  }

  .weather-temperature {
    font-size: 48px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 10px;
  }

  [class^="i-"] {
    font-size: 89px;
  }

  &[class*="background-i-"] {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;

    .bee-weather-icon {
      display: block;
      width: 60px;
      height: 60px;
      margin: 10px auto 0 auto;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  &.background-i-w01d,
  &.background-i-w02d {
    color: #fff;
    background-image: url("/assets/images/weathers-icons/background-sun.svg");

    .bee-weather-icon {
      background-image: url("/assets/images/weathers-icons/bee/bee-sun.svg");
    }
  }

  &.background-i-w03d,
  &.background-i-w04d,
  &.background-i-w10d,
  &.background-i-w10n,
  &.background-i-w09d,
  &.background-i-w09n,
  &.background-i-w13d,
  &.background-i-w13n,
  &.background-i-w11d,
  &.background-i-w11n {
    color: #fff;
    background-image: url("/assets/images/weathers-icons/background-rain.svg");

    .bee-weather-icon {
      background-image: url("/assets/images/weathers-icons/bee/bee-rain.svg");
    }
  }

  &.background-i-w01n,
  &.background-i-w02n,
  &.background-i-w03n,
  &.background-i-w04n {
    color: #fff;
    background-image: url("/assets/images/weathers-icons/background-night.svg");

    .bee-weather-icon {
      background-image: url("/assets/images/weathers-icons/bee/bee-sleep.svg");
    }
  }

  &.background-i-w50d,
  &.background-i-w50n {
    color: #fff;
    background-image: url("/assets/images/weathers-icons/background-fog.svg");

    .bee-weather-icon {
      background-image: url("/assets/images/weathers-icons/bee/bee-cold.svg");
    }
  }
}
