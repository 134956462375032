.popover {
  border-radius: $border-radius;
}

.popover-help-info .popover-arrow {
  margin-left: 1px;
}

.help-button {
  width: 18px;
  height: 18px;
  background: url("/assets/images/icons/info.svg") no-repeat center center;
  background-size: cover;
  position: relative;
  display: block;
  line-height: 1;
  cursor: pointer;
  font-weight: normal;
  font-size: 18px;
}

.card-subtitle .help-button:after {
  margin-top: -9px;
  margin-left: -4px;
}
